
import Vue from 'vue';
import CloudStep03Template from '@/templates/cloud/CloudStep03Template.vue';

export default Vue.extend({
  name: 'CloudStep03Brain',
  components: { CloudStep03Template },
  data() {
    return {
      autoSettle: 'Y',
      payMethod: '9',
    };
  },
  computed: {},
  mounted() {
    this.autoSettle = this.$route.query.auto_settle === 'Y' ? 'Y' : 'N';
    this.payMethod = this.$route.query.pay_method === '9' ? '9' : 'R';
    this.$GlobalLoading.hide();
  },
  methods: {},
});
