
import Vue from 'vue';
import BillLayout from '@/layouts/BillLayout.vue';
import formTitle from '@/assets/data/formTitle/index.json';
import FormLayout from '@/layouts/FormLayout.vue';
import TableLayout from '@/layouts/TableLayout.vue';
import ColumnLayout from '@/layouts/ColumnLayout.vue';
import { getDateStringFromFormat } from '@/utils/common.js';
import { UserInfo } from '@/api/gabia-user/types';

export default Vue.extend({
  name: 'CloudStep03Template',
  components: { BillLayout, TableLayout, ColumnLayout, FormLayout },
  props: {
    autoSettle: {
      type: String,
      default: 'Y',
    },
    payMethod: {
      type: String,
      default: '9',
    },
  },
  data() {
    return {
      formTitle,
      agreeAll: false,
      cloudConsoleUrl: `${process.env.VUE_APP_CLOUD_URL}`,
    };
  },
  computed: {
    userInfo(): UserInfo {
      return this.$store.state.userInfo;
    },
    showUserType(): string {
      return this.userInfo.user_type === 'organization' ? '단체' : '개인';
    },
    showAutoSettleType(): string {
      return this.autoSettle === 'Y' ? '자동 결제' : '수동 결제(매월 직접 결제)';
    },
    showPayMethod(): string {
      return this.payMethod === '9' ? '신용카드' : '계좌(CMS)';
    },
    getCurrnetDate(): string {
      return getDateStringFromFormat('yyyy-mm-dd');
    },
  },
  watch: {
    agreeBoth(newValue) {
      this.agreeAll = newValue;
    },
  },

  methods: {},
});
